<template>
  <v-container class="grey lighten-5">
    <div class="container">
      <div class="two-columns">
        <div class="general-information">
          <h3>General Information</h3>
          <p><strong>Customer Name:</strong> {{meter.customerName}}</p>
          <p><strong>Site Location:</strong> {{meter.siteLocation}}</p>
          <p><strong>Engineer Name:</strong> {{meter.engineerName}}</p>
        </div>
        <div class="metadata">
          <h3>Details</h3>
          <p><strong>Start Time:</strong>{{meter.starttime}}</p>
          <p><strong>End Time:</strong> {{meter.metadata.endtime}}</p>
          <p><strong>Device ID:</strong> {{meter.metadata.deviceid}}</p><!-- ... Add other metadata fields ... -->
        </div>


      </div>

      <div class="electric-meter-information">
        <h2 class="section-title">Electric Meter Information</h2>
        <!-- Repeating section -->
        <div class="single-meter">
          <p><strong>Meter Serial Number:</strong> <!-- Insert meterSerialNo --></p>
          <p><strong>Meter Modem Number:</strong> <!-- Insert meterModemNo --></p>
          <!-- ... Add other fields for this meter ... -->
        </div>
      </div>

      <div class="installation-information">
        <h2 class="section-title">Installation Information</h2>
        <p><strong>System Type:</strong> <!-- Insert systemType --></p>
        <p><strong>AR Type:</strong> <!-- Insert arType when systemType is AR-01 --></p>
        <p><strong>AC Type:</strong> <!-- Insert acType when systemType is AC-01 --></p>
      </div>

      <div class="indoor-unit-information">
        <h2 class="section-title">Indoor Unit Information</h2>
        <!-- Repeating section -->
        <div class="single-indoor-unit">
          <p><strong>Device Barcode:</strong> <!-- Insert qrdata --></p>
          <p><strong>Coolnomix Serial:</strong> <!-- Insert coolnomixSerial --></p>
          <p><strong>Location:</strong> <!-- Insert location --></p>
          <!-- ... Add other fields for this indoor unit ... -->
        </div>
      </div>

      <div class="coolnomix-test-information">
        <h2 class="section-title">Coolnomix Test Information</h2>
        <p><strong>Coolnomix on Test:</strong> <!-- Insert coolnomixOnTest --></p>
        <p><strong>Test Start Date/Time:</strong> <!-- Insert coolnomixTestStartDateTime --></p>
        <!-- ... Add other Coolnomix test fields ... -->
      </div>

    </div>
  </v-container>
</template>
<script>
export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: 'View Meter'
  },
  data() {
    return {
      meter: {
      },
      tab: 0,
      model: 0,
      photoProps: [
        'controlConnectionPointPhoto',
        'powerConnectionPointPhoto',
        'coldSensorPositionPhoto',
        'returnAirSensorPositionPhoto',
        'generalInstallationPhoto',
        'photos2Installation',
        'photos2ThermostatController'
      ],
    }
  },
  computed: {
  },
  methods: {
    getDevice() {
      if (this.$route.params.id !== null) {
        this.$store.dispatch('meters/get', this.$route.params.id).then((result) => {
              this.meter = result;
            }
        );
      }
    }
  },
  mounted() {
    this.getDevice();
  }
}
</script>
<style>
dl {
  padding: 0.5em;
}
dt {
  float: left;
  clear: left;
  width: 400px;
  text-align: right;
  font-weight: bold;
  color: green;
}
dt::after {
  content: ": ";
}
dd {
  margin: 0 0 0 110px;
  padding: 0 0 0.7em 0;
}
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

body {
  font-family: Arial, sans-serif;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-weight: bold;
  background-color: #e0e0e0;
  padding: 5px;
  margin-top: 20px;
}

.two-columns {
  display: flex;
  justify-content: space-between;
}

.two-columns > div {
  width: 48%; /* Slight reduction to account for any possible box-model issues */
}

/* Print specific styles */
@media print {
  body {
    font-size: 12px;
  }
  .container {
    width: 100%;
    margin: 0;
    padding: 10mm;
  }
}
</style>
